import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Alert, Chip, Snackbar, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { CenterNameChipView } from "../../../../../../components/orgComponents/CenterNameChipView";
import { VariancePostCommand } from "../../../../../../services/varianceService/commands/VariancePostCommand";
import { useVarianceSaveMutation } from "../../../../../../services/varianceService/variance-api";
import { useMyProfileQuery } from "../../../../../../services/profileService/profile-api";
import { IncidentWorklistBack } from "../IncidentWorklistBack";
import { VarianceDetailsSave } from "./VarianceDetailsSave";
import { VarianceCancelBtn } from "./VarianceCancelBtn";
import { VarianceApproveBtn } from "./VarianceApproveBtn";
import { VarianceSubmitForApprovalBtn } from "./VarianceSubmitForApprovalBtn";
import { VarianceCompleteBtn } from "./VarianceCompleteBtn";
import { VarianceRejectBtn } from "./VarianceRejectBtn";
import { IncidentAssignUserBtn } from "../AssignUser/IncidentAssignUserBtn";
import { LookupIncidentAssignUser } from "../AssignUser/lookupUser/LookupIncidentAssignUser";
import { VariancePendingApproveBtn } from "./VariancePendingApproveBtn";
import { CenterNameView } from "../../../../../../components/orgComponents";

function VarianceDetailsCommands() {
  const variance = useAppSelector(
    (state) => state.varianceRequest.varianceRequest
  );
  const [varianceSave, status] = useVarianceSaveMutation();
  const [notify, setNotify] = useState(false);
  const [message, setMessage] = useState("");
  useEffect(() => {
    setNotify(status.isSuccess);
  }, [status.isSuccess]);
  const myProfile = useMyProfileQuery();
  const isApprover = () => {
    let isApprover = myProfile.data?.role === "Admin"
    || myProfile.data?.role === "Quality Team" || myProfile.data?.claimsAccess!.find((p) => p.code === "IncidentAdmin") != undefined ||
    myProfile.data?.claimsAccess!.find((p) => p.code === "IncidentQualityTeam") != undefined;
    if (!isApprover) return false;
    return true;
  };
  const isCloser = () => {
    let canClose = myProfile.data?.role === "Admin" ||
    myProfile.data?.claimsAccess!.find((p) => p.code === "IncidentAdmin") != undefined;
    if (!canClose) return false;
    return true;
  }
  const isAssignedOrQualityTeam = () => {
    let isAssigned = myProfile.data?.role === "Quality Team" || myProfile.data?.role === "Admin" ||
    myProfile.data?.claimsAccess!.find((p) => p.code === "IncidentAdmin") != undefined ||
    myProfile.data?.claimsAccess!.find((p) => p.code === "IncidentQualityTeam") != undefined ||
    variance?.assignedUsers.find(p => p.upn === myProfile.data?.upn) && variance.status === "Assigned";
    if (!isAssigned) return false;
    return true;
  }
  const isAssignedUser = () => {
    let assignedUser = variance?.assignedUsers.find(p => p.upn === myProfile.data?.upn) && variance.status === "Assigned";
    if (!assignedUser) return false;
    return true;
  }

  return (
    <React.Fragment>
      <Stack direction="row" justifyContent="space-between" gap={1}>
        <CenterNameView centerId={variance.center!} />
        <Stack direction="row" justifyContent="centered" gap={1}>
          <Chip label={variance.incidentDisplayNumber} />
          <Chip
            label={variance.incidentDisplayStatus}
            color={"info"}
            variant="outlined"
          />
        </Stack>
        <Stack direction="row" gap={1}>
          {variance.status === "Started" && (
            <Stack direction="row" gap={1}>
              <VarianceDetailsSave
                setNotify={setNotify}
                setMessage={setMessage}
              />
              <VarianceSubmitForApprovalBtn
                setNotify={setNotify}
                setMessage={setMessage}
              />
              <VarianceCancelBtn
                setNotify={setNotify}
                setMessage={setMessage}
              />
            </Stack>
          )}
          {variance.status === "Pending" && isApprover() && (
            <Stack direction="row" gap={1}>
              <VarianceDetailsSave
                setNotify={setNotify}
                setMessage={setMessage}
              />
              <IncidentAssignUserBtn
                setNotify={setNotify}
                setMessage={setMessage}
              />
              <LookupIncidentAssignUser />
              {/* <VarianceApproveBtn
                setNotify={setNotify}
                setMessage={setMessage}
                /> */}
              <VarianceRejectBtn
                setNotify={setNotify}
                setMessage={setMessage}
              />
            </Stack>
          )}
          {variance.status === "Assigned" && isAssignedOrQualityTeam() && (
            <Stack direction="row" gap={1}>
              <VarianceDetailsSave
                setNotify={setNotify}
                setMessage={setMessage}
              />
              {isApprover() && (
                <IncidentAssignUserBtn
                  setNotify={setNotify}
                  setMessage={setMessage}
                />
              )}
              <LookupIncidentAssignUser />
              { isAssignedUser() && <VariancePendingApproveBtn
                setNotify={setNotify}
                setMessage={setMessage}
              />}
              <VarianceRejectBtn
                setNotify={setNotify}
                setMessage={setMessage}
              />
            </Stack>
          )}
          {variance.status === "PendingApproval" && isApprover() && (
            <Stack direction="row" gap={1}>
              <VarianceDetailsSave
                setNotify={setNotify}
                setMessage={setMessage}
              />
              <VarianceApproveBtn
                setNotify={setNotify}
                setMessage={setMessage}
              />
              <VarianceRejectBtn
                setNotify={setNotify}
                setMessage={setMessage}
              />
            </Stack>
          )}

          {variance.status === "Approved" && isCloser() && (
            <Stack direction="row" gap={1}>
              <VarianceDetailsSave
                setNotify={setNotify}
                setMessage={setMessage}
              />
              <VarianceCompleteBtn
                setNotify={setNotify}
                setMessage={setMessage}
              />
              <VarianceRejectBtn
                setNotify={setNotify}
                setMessage={setMessage}
              />
            </Stack>
          )}

          <IncidentWorklistBack />
        </Stack>
      </Stack>
      <Snackbar
        open={notify}
        onClose={() => setNotify(false)}
        color="success"
        autoHideDuration={6000}
        message={message}
      />
      <Snackbar open={status.isError}>
        <Alert color="error">
          {"An error has occured: " + JSON.stringify(status.error)}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export { VarianceDetailsCommands };
