import { CarveOutCombo } from "../../../../services/verifyService/model/verification/CarveoutCombo";
import { outPatientLCodeCarveOuts } from "./OutPatientLCodeCarveOuts";

// class that matches an LCODE(s) to a CPT code and returns the LCODE(s) and CPT code
export class CarveOutMatch {
    /**
     * Determines the best matching LCODE combination based on the provided CPT code.
     *
     * @param cpt - A CPT code to be matched against the LCODE combinations.
     * @returns The best matching LCODE object. If no match is found, returns a LCODE with "0" and an empty array.
     */
    findBestMatch(cpt: string): CarveOutCombo[] {
        let bestMatch: CarveOutCombo[] = [];
        for (const combo of outPatientLCodeCarveOuts) {
            const matchedLCODEs = combo.CPTMatch.find(carveOutCode => cpt === carveOutCode);
            if (matchedLCODEs !== undefined) {
                bestMatch.push({ Code: combo.Code, TotalCodeCount: combo.Count, CPTMatch: cpt });
            }
        }
        return bestMatch;
    }
}