import { Verification } from "../../../services/verifyService/model";
import { CompositeSpecification } from "../../../utils/Specification";

export class DOLC9757CodeAlert extends CompositeSpecification<Verification> {
  IsSatisfiedBy(candidate: Verification): boolean {
    let alert = false;
      candidate.procedures.forEach(procedure => {
        if (procedure.code === '63030' || procedure.code === '63047') {
          alert = true;
        }
      });
    return alert;
  } 
}