import { Add } from "@mui/icons-material"
import { Button, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { formatNumber } from "@progress/kendo-intl"
import React from "react"
import { useDispatch } from "react-redux"
import { useAppSelector } from "../../../../../app/hooks"
import { toggleCodeLookup } from "../../../../../app/slices/VerificationModuleSlice"
import { ApptNote } from "./ApptNote"
import { CodeLookup } from "./CodeLookup"
import { CptCodes } from "./CptCodes"
import { PatientOutOfPocket } from "./PatientOutOfPocket"
import { PrintableEstimate } from "./PrintableEstimate"
import { EstimateAlerts } from "./EstimateAlerts"
import { DiagnosisCodes } from "./diagnosisCodes/DiagnosisCodes"
import { ImplantCostBypass } from "./ImplantCostBypass"
import { ResponsibilityNote } from "./ResponsibilityNote"
import { PreServiceCodingReview } from "./PreServiceCodingReveiw"

function Estimate(){
    const dispatch = useDispatch();
    const verification = useAppSelector((state)=> state.verification.verification);
    const totalContribution = ()=>{
        let reimbursement = verification.procedures.map(i => i.reimbursement).reduce((a,b) => a+ b,0);
        let cost = verification.procedures.map(i => i.implantCost).reduce((a,b) => a+b,0);
        return reimbursement - cost;
    }
    return <React.Fragment>
        <ApptNote/>
        <EstimateAlerts/>
        <ResponsibilityNote/>
        <Stack direction='row' justifyContent='space-between' gap={1} alignItems='center' marginLeft='5px' marginBottom='10px' marginTop='5px'>
            <Stack direction='row' gap={2}>
                <Typography sx={{fontWeight:'500'}} variant="body1">Total Reimbursment:</Typography>
                <Typography variant='body1'>{formatNumber(verification.procedures.map(i => i.reimbursement).reduce((a,b) => a+ b,0),'c')}</Typography>
            </Stack>
            <Stack direction='row' gap={2}>
                <Typography sx={{fontWeight:'500'}} variant="body1">Total Contribution:</Typography>
                <Typography variant='body1'>{formatNumber(totalContribution(),'c')}</Typography>
            </Stack>
            <div>
                <Button sx={{marginRight:'5px'}} startIcon={<Add/>} variant="outlined" color='primary' onClick={()=> dispatch(toggleCodeLookup())}> Add Procedure</Button>
                <PrintableEstimate/>
            </div>
        </Stack>
        
        <CptCodes/>
        <br/>
        <Stack direction='column'>
        <PreServiceCodingReview/>
        <ImplantCostBypass/>
        </Stack>
        <DiagnosisCodes/>
        <PatientOutOfPocket/>
        
        <CodeLookup/>
    </React.Fragment>
}

export {Estimate}